import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import "./styles.scss";

const CustomCarousel = ({ images, activeIndex: initialIndex, onClose }) => {
  const [activeIndex, setActiveIndex] = useState(initialIndex);

  useEffect(() => {
    setActiveIndex(initialIndex);
  }, [initialIndex]);

  const slideNext = () => {
    setActiveIndex((val) => (val >= images.length - 1 ? images.length - 1 : val + 1));
  };

  const slidePrev = () => {
    setActiveIndex((val) => (val <= 0 ? 0 : val - 1));
  };

  if (!images || images.length === 0) return null;

  return (
    <div className="backdrop" onClick={onClose}>
      <div className="container__slider" onClick={(e) => e.stopPropagation()}>
        <div className="slider__close" onClick={onClose}>×</div>
        {images.map((image, index) => (
          <div
            className={`slider__item ${activeIndex === index ? "slider__item-active" : ""}`}
            key={index}
          >
            <img src={image.src} alt={image.alt || `Slide ${index}`} />
          </div>
        ))}
        <div className="container__slider__links">
          {images.map((_, index) => (
            <button
              key={index}
              className={`container__slider__links-small ${activeIndex === index ? "container__slider__links-small-active" : ""}`}
              onClick={(e) => {
                e.preventDefault();
                setActiveIndex(index);
              }}
            ></button>
          ))}
        </div>
        {activeIndex < images.length - 1 && (
          <button
            className="slider__btn-next"
            onClick={(e) => {
              e.preventDefault();
              slideNext();
            }}
          >
            {">"}
          </button>
        )}
        {activeIndex > 0 && (
          <button
            className="slider__btn-prev"
            onClick={(e) => {
              e.preventDefault();
              slidePrev();
            }}
          >
            {"<"}
          </button>
        )}
      </div>
    </div>
  );
};

CustomCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  })).isRequired,
  activeIndex: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CustomCarousel;
