import React from "react";

import Main from '../../layout/Main';
import image from "../../assets/images/bright-future.jpg";

import "./styles.scss";

const Moto = () => {

    return (
        <Main className="b-moto-wrapper">

            <div className="section-1">
                <div className="content b-width-wrapper">
                    <h2>
                        Our Motto - <q> Your door to the future </q>
                    </h2>
                    <div className="data">
                        <div className="text">
                            <h3> Our Motto Meaning </h3>
                            <p>
                                Our school aims to develop many opportunities in front of students which may lead them someplace , they never expected.
                            </p>

                            <p>
                                The most powerful equation is E=F, where E is education and F is future. It means <strong>“Education makes a door to bright future.” </strong>  Education, if looked at beyond its conventional boundaries, forms the very essence of all our actions and TIHIS ensures a better future.
                            </p>
                        </div>

                        <div className="image">
                            <img src={image} alt="Bright Future" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-2">
                <div className="content b-width-wrapper">
                    <h2> THE INDIAN HEIGHTS INTERNATIONAL SCHOOL </h2>
                    <p>
                        TIHIS endeavours to develop and chisel a Holistic personality integrated with ethical values, confident to face the challenges of the globalization.
                        School believes to expand knowledge, skills and attitude, moral and social values and nurture students beyond global boundaries.
                    </p>
                    <p>
                        TIHIS is located near Balbehra road, Cheeka.
                        It is an English medium co-educational school, with Central Board of Secondary Education Pattern, New Delhi.
                        The school is built on 2 acres of land.
                    </p>
                    <p>
                        The school promises to replicate the excellence to its students.
                        We believe in the dignity and worth of each student and recognize the importance of his/her responsibilities to the world community.
                        We believe that all youth should have equal opportunities for education, consistent with their individual capabilities and with their personal and social needs.
                        TIHIS offers a nationalistic curriculum in an environment that fosters excellence in school life amidst wide open spaces.
                        It also promotes the natural ability of children to learn through development of their cognitive and thinking skills.
                        Apart from academics, IHI ensures that students are provided all opportunities for honing their co-curricular inclinations through sports, performing and visual arts, and other creative pursuits.
                    </p>
                    <p>
                        In a nutshell, <strong> TIHIS </strong>  is all set to lead <strong> TOUCH THE SKY </strong>  in a new era of education.
                    </p>
                </div>
            </div>

        </Main>
    )
}

export default Moto;