import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import Loader from '../../components/atoms/Loader';

const SchoolRedirect = () => {
    const navigate = useNavigate();
    const { pageUrl } = useParams();

    useEffect(() => {
        navigate(`/pages/${pageUrl}`)
    })

    return (
        <div>
            <Loader loading={true} />
            Redirecting...
        </div>
    );
}

export default SchoolRedirect;