import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getRequest } from "../../../utils/axios";

import phone from "../../../assets/icons/telephone.svg";
import mail from "../../../assets/icons/gmail.svg";
import location from "../../../assets/icons/map.svg";
import facebook from "../../../assets/icons/facebook.svg";
import whatsapp from "../../../assets/icons/whatsapp.svg";
import instagram from "../../../assets/icons/instagram.svg";
import youtube from "../../../assets/icons/youtube.svg";

import "./styles.scss";

const Footer = () => {
    const [data, setData] = useState([]);
    const [hallOfFame, setHallOfFame] = useState([]);
    const [pressReleases, setPressReleases] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            await getData();
            await getHallOfFameAndPressReleases();
        };

        fetchData();
    }, []);

    const getData = async () => {
        const response = await getRequest(`Page/GetActivePagesByCategoryTypeName/pages`);
        setData(response.data);
        localStorage.setItem('docData', JSON.stringify(response.data));
    };

    const getHallOfFameAndPressReleases = async () => {
        const response = await getRequest(`Page/GetActivePagesByCategoryTypeName/events`);
        const hallOfFameData = response.data.filter(item => item.pageId === 109);
        const pressReleasesData = response.data.filter(item => item.pageId === 139);
        setHallOfFame(hallOfFameData);
        setPressReleases(pressReleasesData);
    };

    const renderLinks = (idArray) => {
        return idArray.map(id => (
            data.filter(doc => doc.pageId === id).map(filteredData => (
                <Link key={filteredData.pageId} to={`/pages/${filteredData.pageUrl}`}>{filteredData.pageTitle}</Link>
            ))
        ));
    };

    return (
        <footer className="b-footer">
            <div className="b-width-wrapper">
                <div className="upper-part">
                    <div className="col-1">
                        <h3>Contact Us</h3>
                        <div className="details">
                            <img src={phone} alt="phone" />
                            <div className="content">
                                <p className="title">Call Us</p>
                                <p className="data">01743-220222 - Front Desk</p>
                                <p className="data">(+91) 74960-25620 - Reception</p>
                                <p className="data">(+91) 74960-25623 - Accounts</p>
                            </div>
                        </div>
                        <div className="details">
                            <img src={mail} alt="mail" />
                            <div className="content">
                                <p className="title">Mail Id</p>
                                <a className="data mail-link" href="mailto:info@theindianheights.com">info@theindianheights.com</a>
                            </div>
                        </div>
                        <div className="details">
                            <img src={location} alt="location" />
                            <div className="content">
                                <p className="title">Location</p>
                                <p className="data">Balbehra Road, Cheeka Kaithal, India 136034</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <h3>Quick Links</h3>
                        <div className="links">
                            <div className="left">
                                {renderLinks([70, 77, 76, 79, 80])}
                                {hallOfFame.map(filteredData => (
                                    <Link key={filteredData.pageId} to={`/events/${filteredData.pageUrl}`}>{filteredData.pageTitle}</Link>
                                ))}
                            </div>
                            <div className="right">
                                {renderLinks([81, 69, 83, 49, 84])}
                                {pressReleases.map(filteredData => (
                                    <Link key={filteredData.pageId} to={`/events/${filteredData.pageUrl}`}>{filteredData.pageTitle}</Link>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <h3>Social Links</h3>
                        <div className="social-links">
                            <div className="link-1">
                                <Link to="https://www.facebook.com/indianheightscheeka" target="_blank">
                                    <img src={facebook} alt="facebook" />
                                </Link>
                            </div>
                            <div className="link-2">
                                <Link to="https://api.whatsapp.com/send/?phone=919873102591&text&type=phone_number&app_absent=0" target="_blank">
                                    <img src={whatsapp} alt="whatsapp" />
                                </Link>
                            </div>
                            <div className="link-3">
                                <Link to="https://www.instagram.com/tihischeeka/" target="_blank">
                                    <img src={instagram} alt="instagram" />
                                </Link>
                            </div>
                            <div className="link-4">
                                <Link to="https://www.youtube.com/@tihischeeka" target="_blank">
                                    <img src={youtube} alt="youtube" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lower-part">
                    <p>© Copyright 2022 - The Indian Heights International School.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
