import React from "react";
import Main from '../../layout/Main';
import { Link } from "react-router-dom";

import boy from "../../assets/icons/boy.svg"
import girl from "../../assets/icons/girl.svg"
import addmissionImage from "../../assets/images/Admission.jpg";
import registrationImage from "../../assets/images/registration.png";
import registrationProcessImage from "../../assets/images/registration-process.png";

import "./styles.scss";

const Admission = () => {
    return (
        <Main className="b-addmission-wrapper">

            <div className="section-1">
                <div className="content b-width-wrapper">
                    <div className="left-side">
                        <h2>
                            Admission <span> 2024-2025 </span>
                        </h2>
                        <p>
                            Admission is open for classes Pre-Nursery to Class 9th.
                            The school aspires to produce students who not only excel in academics but also adept in social and ethical values, skills and attitudes to contribute positively to the society.
                            Thus, the admission process is designed to test both aptitude and attitude. While the test assesses the proficiency in various subjects, it will also reveal the child's problem-solving skills. Portions integrated in the admission process will also examine the applicant’s belief and value system on the basis of which he/she makes decisions and the way he/she handles and negotiates the external world.
                            We believe that this method of testing the student will allow us to look at him/her holistically.
                        </p>
                    </div>
                    <div className="right-side">
                        <img src={addmissionImage} alt="Admission" />
                    </div>
                </div>

            </div>

            <div className="section-2">
                <div className="content b-width-wrapper">
                    <div className="left-side">
                        <img src={registrationImage} alt="Registration" />
                    </div>
                    <div className="right-side">
                        <h2>Registration</h2>
                        <p>
                            Registration form for Admission for the Academic Session 2024-2025 will be available from 1st March2024 in school campus as well as on the school website <Link to="/">TIHIS</Link> .
                        </p>
                        <p>
                            The Registration Form should be duly filled in.
                            Parents are required to submit completed hard copy affixed with recent passport size colour photographs of the candidate, father, mother OR guardian on the Registration Form in the school office along with Registration Fees within three days of filling the online registration form.
                        </p>
                        <p>
                            Parents are requested to go through the Prospectus available on our school website carefully before filling the registration form.
                            It is advised that parents should fill the Registration Form themselves to avoid any incomplete information, failing which the registration will be cancelled.
                            The applications will be scrutinized and only those found complete in all respects will be selected for Observation / Assessment.
                        </p>
                    </div>
                </div>
            </div>

            <div className="section-3">
                <div className="content b-width-wrapper">
                    <div className="left-side">
                        <h2>
                            Admission Process
                        </h2>
                        <h3>
                            <strong>
                                Documents to be submitted at the time of admission:
                            </strong>
                        </h3>
                        <ol>
                            <li>
                                School leaving/transfer certificate and previous year's Report Card (without this no student from Class II onwards will be admitted).
                            </li>
                            <li>
                                Original birth certificate.
                            </li>
                            <li>
                                Copy of Aadhar card of the candidate and parents as well as guardian (if one appointed).
                            </li>
                            <li>
                                One recent passport size photograph of the child, parents and Guardian. (Photograph should have a plain white or off-white background).
                            </li>
                            <li>
                                Admission will be cancelled if any deficiency is found between the particulars filled in the Registration Form and the documents submitted for admission.
                            </li>
                        </ol>
                    </div>
                    <div className="right-side">
                        <img src={registrationProcessImage} alt="Registration Process" />
                    </div>
                </div>
            </div>


            <div className="section-4">
                <div className="content b-width-wrapper">
                    <div className="heading">
                        <h2>
                            School Uniform
                        </h2>
                        <strong>
                            On Wednesday & Saturday house T-shirts with white trouser/skirt, white canvas shoes and white socks.
                        </strong>
                    </div>
                    <div className="boxes">

                        <div className="box">
                        <img src={boy} />
                            <h3>
                                 Boys
                            </h3>
                            <ul>
                                <li>
                                    <strong> Summer: </strong> White shirt with blue checked shorts/ dark blue striped trouser, blue/dark blue socks, blue checked/ black shoes, blue check bow/cross bow tie, dark blue tie (3rd-9th) and dark blue belt.
                                </li>
                                <li>
                                    <strong> Winter: </strong> Blue blazer
                                </li>
                            </ul>
                        </div>

                        <div className="box">
                        <img src={girl} />
                            <h3>
                                 Girls
                            </h3>
                            <ul>
                                <li>
                                    <strong> Summer: </strong> White shirt with blue checked/ dark blue striped skirt, blue/ dark blue socks, blue checked/ black shoes, blue check bow/cross bow tie, dark blue tie (3rd-9th) and dark blue belt.
                                </li>
                                <li>
                                    <strong> Winter: </strong> Blue blazer
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

        </Main>
    );
}

export default Admission;