import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Main from '../../layout/Main';
import Loader from "../../components/atoms/Loader";
import { getRequest } from "../../utils/axios";
import './styles.scss';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const imageUrl = "https://dynamicproductstorage.blob.core.windows.net/gallery";
const BLOG_PER_PAGE = 6;

const Blogs = () => {
    const [allBlogs, setAllBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [displayedBlogs, setDisplayedBlogs] = useState([]);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    useEffect(() => {
        getBlogs();
    }, []);

    const getBlogs = async () => {
        try {
            const response = await getRequest(`Page/GetActivePagesByCategoryTypeName/blogs`);
            const blogData = response.data || [];
            setAllBlogs(blogData);
            setDisplayedBlogs(blogData.slice(0, BLOG_PER_PAGE));
            localStorage.setItem('docData', JSON.stringify(blogData));
        } catch (error) {
            console.error("Error fetching blog data:", error);
            setError("Failed to load blog");
        } finally {
            setLoading(false);
        }
    };

    const getImage = (blog) => {
        const { featureImage, pageGallery } = blog;

        if (featureImage) {
            return {
                galleryFilePath: featureImage.galleryFilePath || '',
                galleryFileName: featureImage.galleryFileName || ''
            };
        }

        if (pageGallery && pageGallery.length > 0) {
            const firstImage = pageGallery[0].gallery;
            return {
                galleryFilePath: firstImage.galleryFilePath || '',
                galleryFileName: firstImage.galleryFileName || ''
            };
        }

        return null;
    };

    const loadMoreBlogs = async (event) => {
        event.preventDefault();
        if (isLoadingMore) return;

        setIsLoadingMore(true);

        try {
            const nextBlogs = allBlogs.slice(displayedBlogs.length, displayedBlogs.length + BLOG_PER_PAGE);
            setDisplayedBlogs([...displayedBlogs, ...nextBlogs]);
        } catch (error) {
            console.error("Error loading more blogs:", error);
        } finally {
            setIsLoadingMore(false);
        }
    };

    return (
        <Main className="b-blog-wrapper">
            <Loader loading={loading} backdrop={true} />
            <div className='b-width-wrapper wrapper'>
                <h1>
                    Blogs
                </h1>
                {loading ? (
                    <p>Loading Blogs...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <>
                        <div className='b-blogs-containers'>
                            {displayedBlogs.length > 0 ? (
                                displayedBlogs.map((blog, index) => {
                                    const image = getImage(blog);
                                    const imagePath = image ? `${imageUrl}/${image.galleryFilePath.replace(/\\/g, '/')}${image.galleryFileName}` : '';
                                    return (
                                        <Card key={index}>
                                            <Link to={`/blogs/${blog.pageUrl}`} className="card-img-wrapper">
                                                {image ? (
                                                    <Card.Img variant="top" src={imagePath} alt="blog" />
                                                ) : (
                                                    <p>No image available</p>
                                                )}
                                            </Link>
                                            <Card.Body>
                                                <Link to={`/blogs/${blog.pageUrl}`} className='color'>
                                                    <Card.Title>{blog.pageTitle}</Card.Title>
                                                </Link>
                                                <Card.Text className="card-text">{blog.shortDescription}</Card.Text>
                                                <Button variant="primary" as={Link} to={`/blogs/${blog.pageUrl}`}>
                                                    Read More
                                                </Button>
                                            </Card.Body>
                                        </Card>

                                    );
                                })
                            ) : (
                                <p>No blogs found.</p>
                            )}
                        </div>
                        {displayedBlogs.length < allBlogs.length && (
                            <Button onClick={loadMoreBlogs} className='b-load-more-btn' variant="secondary" disabled={isLoadingMore}>
                                {isLoadingMore ? 'Loading...' : 'Load More'}
                            </Button>
                        )}
                    </>
                )}
            </div>
        </Main>
    );
}

export default Blogs;
