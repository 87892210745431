import React from "react";
import Main from '../../layout/Main';

import "./styles.scss";

const Document = () => {
  return (
    <Main classname="b-document-wrapper">
      <div className="b-width-wrapper">
        <div className="content">
            <h1>
                Documents and <span> Information </span> 
            </h1>
            <ol>
                <li>
                    <a href="#"> Recogination Certificate Upto 8th </a>
                </li>

                <li>
                    <a href="#"> NOC from State Board Upto 8th </a>
                </li>

                <li>
                    <a href="#"> Recogination Certificate 9th and 10th </a>
                </li>

                <li>
                    <a href="#"> NOC from State Board for 9th & 10th </a>
                </li>

                <li>
                    <a href="#">Structural Stability Certificate</a>
                </li>

                <li>
                    <a href="#"> Trust Registration </a>
                </li>

                <li>
                    <a href="#"> Fire NOC </a>
                </li>

                <li>
                    <a href="#"> Hygienic Certificate </a>
                </li>

                <li>
                    <a href="#"> Parents Teachers Association </a>
                </li>

                <li>
                    <a href="#"> School Management Committee </a>
                </li>

                <li>
                    <a href="#"> Calender </a>
                </li>

                <li>
                    <a href="#"> Mandatory Disclosure </a>
                </li>

                <li>
                    <a href="#"> Fee Structure </a>
                </li>

                <li>
                    <a href="#"> Self Certification </a>
                </li>

                <li>
                    <a href="#"> Certificate of Land </a>
                </li>

                <li>
                    <a href="#"> ECS Certificate </a>
                </li>
                <li>
                    <a href="#"> Video </a>
                </li>

                <li>
                    <a href="#"> School Infra </a>
                </li>

                <li>
                    <a href="#"> Compliance </a>
                </li>

                <li>
                    <a href="#"> Undertaking regarding 9th grade students </a>
                </li>

                <li>
                    <a href="#"> Transport Safety Certificate </a>
                </li>
            </ol>
        </div>

        <iframe src=""></iframe>
      </div>
    </Main>
  );
}   

export default Document;