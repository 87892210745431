import axios from "axios";
const API_URL = "https://dynamicapi.memeticsolutions.in/api"; //process.env?.REACT_APP_API_URL 

const getToken = () => {
    let token = localStorage.getItem('school-token');
    return token;
}

const getClientToken = async () => {
    const host = 'theindianheights.com';
    const resp = await axios.get(`${API_URL}/Client/GetToken/${host}`);
    localStorage.setItem("school-token", resp?.data);
    return resp.data;
}

const getRequest = async (url, headers) => {
    let token = getToken();
    if (!token) {
        token = await getClientToken();
    }
    const myheaders = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    }
    try {
        const resp = await axios.get(`${API_URL}/${url}`, { ...myheaders, ...headers });
        return resp
    } catch (error) {
        if (error?.response?.status === 401) {
            localStorage.clear();
            return await getRequest(url, headers);
        }

        return error
    }

}

const postRequest = async (url, params, headers) => {
    let token = getToken();
    if (!token) {
        token = await getClientToken();
    }
    const myheaders = {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    }
    try {
        const resp = await axios.post(`${API_URL}/${url}`, params, { ...myheaders, ...headers });
        return resp
    } catch (err) {
        if (err.response.status === 401) {
            localStorage.clear();
            return await postRequest(url, params, headers);
        }
        return err
    }

}

export { getRequest, postRequest }