import React, { useEffect, useState } from 'react';
import { getRequest } from '../../utils/axios';
import { useParams, useLocation, useNavigate } from "react-router-dom";

import './styles.scss';

import Loader from '../../components/atoms/Loader';
import NoContent from '../../components/atoms/NoContent';
import Main from '../../layout/Main';
import CustomCarousel from '../../components/organs/Custom-Carousel';

const imageUrl = "https://dynamicproductstorage.blob.core.windows.net/gallery";

const PagesInfo = () => {
  const { categoryTypeName, pageUrl } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [pageName, setPageName] = useState("");
  const [desc, setDesc] = useState("");
  const [gallery, setGallery] = useState([]);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    getWhatsNewInfo();
  }, [pageUrl]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get('tab');
    
    if (tabParam && tabParam === 'video' && videos.length > 0) {
      setActiveTab('video');
    } else if (gallery.length > 0) {
      setActiveTab('gallery');
    } else if (videos.length > 0) {
      setActiveTab('video');
    }
  }, [gallery, videos]);

  const getWhatsNewInfo = async () => {
    setLoading(true);
    try {
      const pageResponse = await getRequest(`Page/GetPageByUrl/${categoryTypeName}/${pageUrl}`);
      setPageName(pageResponse?.data?.pageName || "");
      setDesc(pageResponse?.data?.pageDescription || "");

      const pageId = pageResponse?.data?.pageId;
      if (pageId) {
        const galleryResponse = await getRequest(`PageGallery/GetPageGallery/${pageId}`);
        const fetchedGallery = galleryResponse?.data || [];
        setGallery(fetchedGallery);

        const videoResponse = await getRequest(`PageVideo/GetPageVideo/${pageId}`);
        const fetchedVideos = videoResponse?.data || [];
        setVideos(fetchedVideos);

        const queryParams = new URLSearchParams(location.search);
        const tabParam = queryParams.get('tab');

        if (tabParam && tabParam === 'video' && fetchedVideos.length > 0) {
          setActiveTab('video');
        } else if (fetchedGallery.length > 0) {
          setActiveTab('gallery');
        } else if (fetchedVideos.length > 0) {
          setActiveTab('video');
        }
      }
    } catch (error) {
      console.error("Error fetching page info:", error);
    }
    setLoading(false);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    navigate(`${location.pathname}?tab=${tab}`);
  };

  const handleImageClick = (index) => {
    setActiveIndex(index);
    setIsCarouselOpen(true);
  };

  const closeCarousel = () => {
    setIsCarouselOpen(false);
  };

  const convertToEmbedURL = (url) => {
    return url.replace("watch?v=", "embed/");
  };

  const renderGallery = () => (
    <div className="masonry">
      {gallery.map((img, index) => (
        <div key={index} className="masonry-item" onClick={() => handleImageClick(index)}>
          <img
            src={`${imageUrl}/${img.galleryFilePath.replace(/\\/g, '/')}${img.galleryFileName}`}
            alt={img.galleryFileTitle || `Gallery image ${index}`}
            loading="lazy"
          />
        </div>
      ))}
    </div>
  );

  const renderVideos = () => (
    <div className="video-list">
      {videos.map((video, index) => (
        <div key={index} className="video-item">
          <iframe
            width="560"
            height="315"
            src={convertToEmbedURL(video.videoFilePath)}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      ))}
    </div>
  );

  return (
    <Main className={`b-whats-new-info-wrapper ${categoryTypeName === 'events' ? 'events' : ''}`}>
      <Loader loading={loading} backdrop={true} />
      {!loading && (
        <>
          {categoryTypeName === 'events' && pageName && <h1>{pageName}</h1>}
          {desc ? (
            <div dangerouslySetInnerHTML={{ __html: desc }}></div>
          ) : (
            <NoContent />
          )}

          {/* Tabs for Gallery and Video */}
          {categoryTypeName === 'events' && (
            <div className="tabs">
              {gallery.length > 0 && (
                <button
                  className={activeTab === 'gallery' ? 'active' : ''}
                  onClick={() => handleTabChange('gallery')}
                >
                  Gallery
                </button>
              )}
              {videos.length > 0 && (
                <button
                  className={activeTab === 'video' ? 'active' : ''}
                  onClick={() => handleTabChange('video')}
                >
                  Video
                </button>
              )}
            </div>
          )}

          {/* Content based on active tab */}
          {activeTab === 'gallery' && gallery.length > 0 ? renderGallery() : null}
          {activeTab === 'video' && videos.length > 0 ? renderVideos() : null}

          {/* Handle no content case */}
          {activeTab === 'gallery' && gallery.length === 0 && !desc && <NoContent message="No gallery available." />}
          {activeTab === 'video' && videos.length === 0 && !desc && <NoContent message="No videos available." />}

          {/* Carousel for gallery */}
          {isCarouselOpen && (
            <CustomCarousel
              images={gallery.map(img => ({
                src: `${imageUrl}/${img.galleryFilePath.replace(/\\/g, '/')}${img.galleryFileName}`,
                alt: img.galleryFileTitle
              }))}
              activeIndex={activeIndex}
              onClose={closeCarousel}
            />
          )}
        </>
      )}
    </Main>
  );
};

export default PagesInfo;
