import React from 'react';
import Main from '../../layout/Main';

import logo from '../../assets/icons/logo.jpg';
import schoolImage from '../../assets/images/school-front-image.png';

import "./styles.scss";

const About = () => {
    return (
        <Main className="b-about-wrapper">

            <div className='content'>
                <div className='b-width-wrapper'>

                    <div className='section-1'>
                        <div className='left-side'>
                            <h2>
                                Welcome to <span> TIHIS </span>
                            </h2>
                            <p>
                                TIHIS will provide world className facilities to students, their parents and the team members.
                                All the className rooms in the school are technology enabled classNamerooms with smart boards installed in it.
                                We follow the thematic study pattern to make the students learn through activities rather than by rote learning.
                                We maintain a competitive student teacher ratio to provide personal attention to each and every student.
                            </p>

                            <div className='skills'>
                                <div className='bar' data-text="Creativity skills"></div>
                            </div>
                            <div className='science'>
                                <div className='bar' data-text="Exact sciences"></div>
                            </div>

                        </div>
                        <div className='right-side'>
                            <img src={schoolImage} alt='School Front' />
                        </div>
                    </div>

                    <div className='section-2'>
                        <div className='image-side'>
                            <img src={logo} />
                        </div>
                        <div className='text-side'>
                            <h2>
                                Our Mission
                            </h2>
                            <p>
                                TIHIS understands the need of one stop solution for all routine requirements of a school going child.
                                As modern day parents are quite busy - hence the school needs to provide the most for the routine requirement of a child.
                            </p>
                        </div>

                    </div>

                    <div className='section-3'>

                        <div className='content width-wrapper'>
                            <h3>
                                What measures does the school take to make the students fluent in English?
                            </h3>

                            <p>
                                Efforts are made to ensure that all students start conversing in English as early as possible.
                                At TIHIS we recognize the socio-cultural background of our students and that is the reason why spoken English classNamees form a part of the Routine Time Table and the school Assembly.
                                To improve communication skills, students should be given every opportunity to participate in competition for news bulletin, poems, scriptures, extempore speeches, quizzes etc. in English.


                            </p>
                            <p>
                                However, we seek active participation of the parents in this regard.
                                We suggest that they should speak in English at home and encourage their wards to speak in English too. The child should be provided with age appropriate English books, newspapers and magazines.
                                This will foster good reading habit and will propagate reading skills.
                            </p>

                            <h3>
                                Course of Study
                            </h3>

                            <p>
                                The school prepares student for various exams. The school has classNamees from Pre-Nursery to className 7th (which will be upgraded every year on, up to 12th).
                                The medium of instruction is English for all the classNamees, but school also lays an equal emphasis on Hindi too.
                                The subjects taught according to the CBSE curriculum from Pre-Nursery to className 7th are as follows:
                                English, Hindi, Punjabi, Mathematics, Environmental Studies, Science, Social Studies, Computer Studies, Value Education, General Knowledge, Vocal and Instrumental Music, Dance, Art & Craft and Sports & Physical Education.
                            </p>
                        </div>

                    </div>

                </div>

            </div>


        </Main>
    );
}

export default About;