import React from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";

import Home from "./pages/home/index";
import About from "./pages/about/index";
import Videos from "./pages/videos/index";
import PagesInfo from "./pages/pages-info/index";
import Document from "./pages/doc-and-info/index";
import Principal from "./pages/principal/index";
import Admission from "./pages/admission";
import Jobs from "./pages/jobs";
import Blogs from "./pages/blogs";
import Tribute from "./pages/tribute";
import Events from "./pages/events";
import EventDetail from "./pages/event-detail";
import Contact from "./pages/contact";
import Moto from "./pages/moto/index";
import Values from "./pages/values";
import Curriculum from "./pages/curriculum";
import Badge from "./pages/badge";
import Refund from "./pages/refund-policy/index";
import PrivacyPolicy from "./pages/privacy-policy/index";
import TermsAndConditions from "./pages/terms-and-conditions/index";
import SchoolRedirect from "./pages/school-redirect";

const router = createBrowserRouter(
    [
        {
            path: "/",
            element: <Home />,
        },
        {
            path: "/events",
            element: <Events />,
        },
        {
            path: "/videos",
            element: <Videos />,
        },
        {
            path: "/eventDetail",
            element: <EventDetail />,
        },
        {
            path: "*",
            element: <Navigate to="/" replace />,
        },
        {
            path: "/school/:pageUrl",
            element: <SchoolRedirect />,
        },
        {
            path: "/:categoryTypeName/:pageUrl",
            element: <PagesInfo />,
        },
        {
            path: "/document",
            element: <Document />,
        },
        {
            path: "/about",
            element: <About />,
        },
        {
            path: "/jobs",
            element: <Jobs />,
        },
        {
            path: "/blogs",
            element: <Blogs />,
        },
        {
            path: "/principal",
            element: <Principal />,
        },
        {
            path: "/admission",
            element: <Admission />,
        },
        {
            path: "/contact",
            element: <Contact />,
        },
        {
            path: "/tribute",
            element: <Tribute />,
        },
        {
            path: "/moto",
            element: <Moto />,
        },
        {
            path: "/values",
            element: <Values />,
        },
        {
            path: "/curriculum",
            element: <Curriculum />,
        },
        {
            path: "/badge",
            element: <Badge />,
        },
        {
            path: "/refund",
            element: <Refund />,
        },
        {
            path: "/privacy",
            element: <PrivacyPolicy />,
        },
        {
            path: "/terms",
            element: <TermsAndConditions />,
        },
    ]
);

const Routes = () => {
    return (
        <RouterProvider router={router} />
    );
}

export default Routes;
