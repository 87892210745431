import React from "react";

import principalImage from "../../assets/images/PRINCIPAL.jpg";

import './styles.scss';


const Principal = () => {
    return (
        <div className="b-principal-wrapper">
            <div className='b-width-wrapper'>
                <div className='section-1'>
                    <div className='left-side'>
                        <h2>
                            From the desk of the <span> PRINCIPAL </span>
                        </h2>

                        <h3>
                            Dear Parents and TIHISites
                        </h3>
                        <em> " Education is not preparation of life, education is life itself." </em>
                        <h3>
                            A very warm welcome to TIHIS!
                        </h3>
                        <p>
                            <em> <strong> “Education awakens the power and beauty that lies within us.” </strong> </em>
                            Education does not only mean academic excellence, but it also is a harmonious and synchronized combination of hand (skills like various arts), head (Intellectual Power) and heart (Value System).
                            In the present era of digitalized world, it is the biggest challenge before educators and parents, to nurture the young minds with the indelible impressions of a holistic education.
                            Therefore, we came up with a vision to foster different facets of a student in order to see him/her developing as a vibrant student, responsible citizen and above all a generous and sentient human being.
                            Our pedagogy is child centric, with emphasis on over- all growth and development of our students.
                            We are passionate about offering children an exciting, stimulating and rich curriculum, based on real reasons for learning.
                            We hope that you will share with us the belief that every child should have the opportunity to meet their potential and develop the academic, creative, social and spiritual skills that will enable them to fulfill a happy and prosperous life.
                            Our school enables pupils from the age of 3years-12years to meet the challenges of an education that will prepare them for life in the 21st century. We use a wide range of styles and resources to excite, motivate and challenge our students to succeed.
                            We have many excellent resources which include - Interactive Whiteboard technology in every classroom, fully A.C campus, an indoor splash pool, skating, and a newly developed E-library with books to amuse, inspire and inform. We are a school that is at the centre of our community.
                            Please take the time to visit our school to see just how exciting it is.
                        </p>
                    </div>
                    <div className='right-side'>
                        <img src={principalImage} alt='principal' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Principal;