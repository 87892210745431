import React from "react";
import { Link } from "react-router-dom";
import Main from '../../layout/Main';

import './styles.scss'

const Terms = () => {



    return (
        <Main className="b-terms-wrapper">
            <div className="content b-width-wrapper ">

                <h2> Terms and Conditions </h2>

                <div className="data">

                    <h3> Terms and Conditions </h3>

                    <p>
                        We reserve the right in our sole discretion to edit or delete any document, information or other content appearing on the Site.


                    </p>

                    <h3>
                        Use of Information
                    </h3>

                    <p>
                        We reserve the right and you authorize us to the use and assignment of all information regarding Site uses by you and all information provided by you in any manner consistent with Our Privacy Policy.

                        All of the content and Information is brought to you by The Indian Heights International Schools. For more information feel free to Contact Us
                    </p>

                    <h3>
                        Disclaimer
                    </h3>

                    <p>
                        The official website of The Indian Heights International Schools has been developed to provide information related to the school.
                        The documents and information displayed on the website are for reference purpose only and cannot be claimed to be a legal document.
                        The information contained on the website is in sync on the inputs received from various sections of school.
                        Though all efforts have been made to ensure the accuracy of the content, the same should not be constructed as a statement of law or use of any legal Services.
                        Links to other websites that have been included on this website are provided for public convenience only. The Indian Heights International Schools is not responsible for the content or reliability of linked websites and does not necessarily endorse the views expressed within them. We cannot guarantee the availability of such linked Pages of all times.
                    </p>

                    <h3>
                        Privacy Policy
                    </h3>

                    <p>
                        Please visit <Link to="/refund"> Privacy Policy </Link> Page
                    </p>

                    <h3>
                        Refund Policy
                    </h3>

                    <p>
                        Please visit <Link to="/refund"> Refund Policy </Link> Page
                    </p>

                </div>

            </div>
        </Main>
    );
}

export default Terms;